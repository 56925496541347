import React from "react";
import { css } from "@emotion/core";
import { useCycle } from "framer-motion";
import tw from "tailwind.macro";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import HamburgerIcon from "./HamburgerIcon";
import HeaderMenuModal from "./HeaderMenuModal";
import HeaderLinkDropDown from "./HeaderLinkDropDown";
import LanguageSelector from "../i18n/LanguageSelector";
import { useEffect } from "react";
import CloudinaryBackgroundImage from "../CloudinaryBackgroundImage";

const HeaderContent = styled.div`
  ${tw`flex flex-wrap content-center items-center w-full`}
`;
const HomeLink = styled.a`
  ${tw`ml-sm`}
`;
const LinksNavContainer = styled.nav`
  ${tw`hidden xl:flex md:px-sm`}
  display:flex
justify-content: flex-end;
`;

const HeaderContainer = styled.nav`
  background-image: ${(props) => {
    if (
      props.header_background_image &&
      props.header_background_image.url &&
      !props.navbarTransparent
    ) {
      return `url(${props.header_background_image.url})`;
    }
  }};

  &.navbar-fixed {
    ${tw`fixed top-0 w-full z-40 py-base my-auto`}
  }

  &.navbar {
    ${(props) => {
      switch (props.navbar_color) {
        case "primary-lighter":
          return tw`bg-primary-lighter text-navbarfont-normal`;
        case "primary-normal":
          return tw`bg-primary-normal text-navbarfont-inverse`;
        case "primary-darker":
          return tw`bg-primary-darker text-navbarfont-inverse`;
        case "black":
          return tw`bg-black text-navbarfont-inverse`;
        default:
          return tw`bg-white text-navbarfont-normal`;
      }
    }}
    ${tw`flex z-40 shadow-none font-light lg:px-base px-sm lg:h-24 h-16`};
    transition: all 0.3s;
  }

  &.navbar-transparent {
    ${tw`bg-transparent shadow-none text-white`}
  }

  &.navbar-transparent .navbar-item {
    ${tw`text-white`}
  }
`;

const LinkNav = styled.a``;

const HamburgerIconContainer = styled.div`
  ${tw`inline-block xl:hidden`}
`;

const LinkElement = ({ link }) => {
  const colors = {
    success: css`
      ${tw`border border-primary-normal rounded-full text-primary-normal bg-white px-sm py-sm`}
    `,
    normal: css`
      ${tw``}
    `,
  };
  const styleToApply = css`
    ${colors[link.link_style]}
  `;

  if (!link.type) {
    return (
      <Link
        css={css`
          ${tw`mx-sm`}
          ${styleToApply}
        `}
        to={link.link_url.text}
      >
        {link.link_label.text}
      </Link>
    );
  } else if (link.type === "dropdown") {
    return (
      <HeaderLinkDropDown
        links={link.dropdownLinks}
        label={link.link_label.text}
        link_url={link.link_url.text}
      />
    );
  }
};

const sidebar = {
  open: {
    clipPath: `circle(${1000 * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
    opacity: 1,
  },
  closed: {
    clipPath: "circle(0px at 0px 0px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const Header = ({
  logo,
  alt_logo,
  logo_size,
  language,
  title,
  links,
  navbarTransparent,
  languageOptions,
  header_background_image,
  navBarCanBeTransparent,
  isDistractionFree,
  navbar_color,
  inverted_color,
}) => {
  const [hasToShowModal, toggleModal] = useCycle(false, true);

  useEffect(() => {
    document.body.style.overflowY = "visible";
  }, []);

  let backgroundUrl;
  // if(header_background_image && header_background_image.url) {
  //     backgroundUrl = CloudinaryUtils.getOptimisedUrl(header_background_image.url, {
  //         height: 500 , width: 1350, crop: "fit"
  //     })
  // }

  const getLogo = (transparent) => (transparent ? alt_logo.url : logo.url);
  const displayLanguageSelector = languageOptions && languageOptions.length;
  const homeLink = language === "english" ? "/en" : "/";

  return (
    <HeaderContainer
      header_background_image={header_background_image}
      navbarTransparent={navbarTransparent}
      navbar_color={navbar_color}
      className={`navbar navbar-fixed ${
        navbarTransparent && navBarCanBeTransparent ? "navbar-transparent" : ""
      }`}
    >
      <HeaderContent
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link
          to={homeLink}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            css={css`
              ${tw`w-32 h-auto py-sm`}
            `}
          >
            <CloudinaryBackgroundImage
              background_height={"7vh"}
              background_size={"contain"}
              url={getLogo(navbarTransparent && navBarCanBeTransparent)}
            />
          </div>
          {title && title.text ? (
            <HomeLink href={homeLink}>{title.text}</HomeLink>
          ) : (
            ""
          )}
        </Link>
        {!isDistractionFree ? (
          <React.Fragment>
            {displayLanguageSelector && (
              <LanguageSelector languageOptions={languageOptions} />
            )}
            <LinksNavContainer>
              <div
                css={css`
                  justify-content: flex-end;
                `}
              >
                {links.map((link, index) => (
                  <LinkElement key={link + index} link={link} />
                ))}
              </div>
            </LinksNavContainer>
            <HamburgerIconContainer>
              <HamburgerIcon
                invertColors={navbarTransparent && navBarCanBeTransparent}
                onClick={() => {
                  toggleModal();
                }}
              />
            </HamburgerIconContainer>
          </React.Fragment>
        ) : null}
      </HeaderContent>
      {hasToShowModal && (
        <HeaderMenuModal
          header_background_image={header_background_image}
          navbarTransparent={navbarTransparent}
          hasToShowModal={hasToShowModal}
          color={navbar_color}
          onCloseClick={() => toggleModal()}
          logo={logo}
          links={links}
          title={title}
        />
      )}
    </HeaderContainer>
  );
};

export default Header;
