import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import * as R from "ramda";

const FooterContainer = styled.div`
  ${tw`flex flex-wrap w-full items-center md:mx-auto md:px-lg py-base my-auto bg-white border-t mt-base`}
  ${(props) => {
    switch (props.footer_color) {
      case "primary-lighter":
        return tw`bg-primary-lighter text-primary-darker`;
      case "primary-normal":
        return tw`bg-primary-normal text-white`;
      case "primary-darker":
        return tw`bg-primary-darker text-white`;
      case "black":
        return tw`bg-black text-white`;
      default:
        return tw`bg-white`;
    }
  }};
`;

const Logo = styled.img`
  ${(props) => {
    switch (props.logoSize) {
      case "12":
        return tw`w-12`;
      case "16":
        return tw`w-16`;
      case "14":
        return tw`w-14`;
      case "10":
        return tw`w-10`;
      default:
        return tw`w-16`;
    }
  }}
`;

const FooterContentContainer = styled.div`
  ${tw`md:flex w-full md:justify-between`}
`;

const FooterLink = styled.a`
  ${tw`underline`}
`;

const FooterCompanyInfoContainer = styled.div`
  ${tw`px-base py-base md:px-0 md:pl-base`}
`;

const FooterCompanySocialContainer = styled.div`
  ${tw`px-base py-base md:px-0 md:pl-base lg:w-64 md:w-3/5 w-full`}
`;

const FooterCompanySocialLink = styled.a`
  ${tw`w-12 inline-block mr-2`}
`;

const FooterCompanyInfoElement = styled.div``;

const FooterCompanyDisclaimer = styled.div`
  ${tw`mt-4 md:w-3/4 lg:w-1/2 w-full text-sm italic`}
`;

const MadeByOmochi = styled.a`
  ${tw`inline-block my-3 text-gray-700 hover:text-gray-900 hover:underline`}
`;

const Footer = ({
  footerLogo,
  footerInverseLogo,
  footerColor,
  footerBody,
  displaySectionTitles,
  companyInfo,
  footerLogoSize,
  footerSocialLinks,
}) => {
  const {
    addressMain,
    addressSecondLine,
    phone,
    phoneLink,
    email,
    disclaimer,
  } = companyInfo;
  const defaultLogoSize = R.defaultTo("16");
  const logoSize = defaultLogoSize(footerLogoSize);
  return (
    <FooterContainer footer_color={footerColor}>
      <div>
        <Logo size={logoSize} src={footerLogo.url} alt="" />
      </div>
      <FooterContentContainer>
        <FooterCompanyInfoContainer>
          <FooterCompanyInfoElement>{addressMain}</FooterCompanyInfoElement>
          <FooterCompanyInfoElement>
            {addressSecondLine}
          </FooterCompanyInfoElement>
          <FooterCompanyInfoElement>
            <FooterLink href={`tel:${phoneLink}`}>{phone}</FooterLink>
          </FooterCompanyInfoElement>
          <FooterCompanyInfoElement>
            <FooterLink href={`mailto:${email}`}>{email}</FooterLink>
          </FooterCompanyInfoElement>
          <FooterCompanyDisclaimer>{disclaimer}</FooterCompanyDisclaimer>
          <MadeByOmochi href="https://epotignano.com/" target="_blank">
            Made with ❤️ by <b>Epotignano Digital</b>
          </MadeByOmochi>
        </FooterCompanyInfoContainer>
        {footerSocialLinks && footerSocialLinks.length ? (
          <FooterCompanySocialContainer>
            {footerSocialLinks.map((social) => {
              return (
                <FooterCompanySocialLink href={social.link} target="_blank">
                  <img src={social.icon} />
                </FooterCompanySocialLink>
              );
            })}
          </FooterCompanySocialContainer>
        ) : null}
      </FooterContentContainer>
    </FooterContainer>
  );
};
export default Footer;
