import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import { navigate } from "gatsby";
import CloudinaryBackgroundImage from "../../CloudinaryBackgroundImage";
import { css } from "@emotion/core";
import { I18NContext } from "../Context";
import Icon from "../../Icon";

const StyledSelector = styled.div`
  ${tw`inline-block relative`}
  &:hover {
    ${tw`cursor-pointer`}
  }
`;

const StyledSelectorSelection = styled.div`
  ${tw`inline-flex items-center`}
`;

const StyledSelectorOptions = styled.div`
  ${(props) => {
    if (props.displayOptions) {
      return tw`absolute block`;
    }
    return tw`hidden`;
  }}
  ${tw`bg-white p-sm rounded text-black`}
`;

const StyledSelectorElement = styled.div`
  ${tw`py-xsm px-sm w-full`}
  &:hover {
    ${tw`bg-blue-200`}
  }
`;

const LanguageFlagContainer = styled.div`
  ${tw`inline-block`}
`;
const LanguageElementLabel = styled.div`
  ${tw`inline-block`}
`;

const LanguageSelector = ({ languageOptions }) => {
  const [displayOptions, setDisplayOptions] = useState(false);
  const i18NContext = useContext(I18NContext);
  const language = i18NContext.language;

  const getLanguageToDisplay = () => {
    let optionToDisplay = {};
    languageOptions.some((languageOption) => {
      if (languageOption.languageValue === language) {
        optionToDisplay = languageOption;
        return true;
      }
      return false;
    });
    return optionToDisplay;
  };
  const languageOptionToDisplay = getLanguageToDisplay();
  return (
    <StyledSelector
      onMouseEnter={() => setDisplayOptions(true)}
      onMouseLeave={() => setDisplayOptions(false)}
      onClick={() => setDisplayOptions(true)}
    >
      <StyledSelectorSelection>
        {languageOptionToDisplay.languageLabel}
      </StyledSelectorSelection>
      <StyledSelectorOptions displayOptions={displayOptions}>
        {languageOptions.map((languageOption) => {
          if (languageOption.languageValue !== language) {
            return (
              <StyledSelectorElement
                onClick={() => {
                  navigate(languageOption.languageUrl);
                }}
              >
                <LanguageFlagContainer>
                  <CloudinaryBackgroundImage
                    css={css`
                      ${tw`h-32`}
                    `}
                    url={languageOption.languageFlagUrl}
                    image_height={"64"}
                    background_height="24px"
                    background_size="contain"
                  />
                </LanguageFlagContainer>
                <LanguageElementLabel>
                  {languageOption.languageLabel}
                </LanguageElementLabel>
                {/*<Icon  width={"18"} height={"18"} color={"white"} />*/}
              </StyledSelectorElement>
            );
          }
          return null;
        })}
      </StyledSelectorOptions>
    </StyledSelector>
  );
};

export default LanguageSelector;
