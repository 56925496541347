const cloudinaryBase = 'https://res.cloudinary.com';

const CloudinaryUtils = {
    cloudinaryUrl: ``,
    getVideoUrl: () => `${CloudinaryUtils.cloudinaryUrl}/video/upload`,
    getImageUrl: () => `${CloudinaryUtils.cloudinaryUrl}/image/fetch`,
    setCloudinaryUrl: (cloudName) => {
        CloudinaryUtils.cloudinaryUrl = `${cloudinaryBase}/${cloudName}`
    },
    getOptimisedUrl: (imageUrl, options) => {
        // const parsedImageUrl = imageUrl.replace("?auto=compress,format", "");
        const parsedImageUrl = imageUrl;
        const optionsToApply = options || {};
        const url = `${CloudinaryUtils.getImageUrl()}/w_${optionsToApply.width || 300},h_${optionsToApply.height || 300},q_${optionsToApply.quality || 80},c_${optionsToApply.crop || 'fill'}`
            + `/${parsedImageUrl}`;
        return url;
    },
    getOptimisedAvatar: (imageUrl, options) => {
        const optionsToApply = options || {};
        const url = `${CloudinaryUtils.getImageUrl()}/w_${optionsToApply.width || 300},h_${optionsToApply.height || 300},c_${optionsToApply.crop || 'fill'},g_face,r_max`
            + `/${imageUrl}`;
        return url;
    },
    getMobileVideo : (videoUrl, options) => {
        const optionToApply = options || {};
        const url = `${CloudinaryUtils.getVideoUrl()}/${videoUrl}`;
        return url;
    }
};

export default CloudinaryUtils;
