import React from "react";

import styled from "@emotion/styled";
import tw from "tailwind.macro";
import { css } from "@emotion/core";

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.125rem;
    ${props => {
      if(props.invertColors) {
        return tw`bg-navbarfont-inverse`;
      }
      return tw`bg-navbarfont-normal`;
    }}
    border-radius: 99px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  :first-child {
    transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
  }

  :nth-child(2) {
    opacity: ${({ open }) => (open ? "0" : "1")};
    transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
  }

  :nth-child(3) {
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
  }
`;

const HamburgerIcon = ({ onClick, invertColors }) => {
  return (
    <StyledBurger
      invertColors={invertColors}
      onClick={() => onClick()}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default HamburgerIcon;
