import React from "react";
import styled from "@emotion/styled";

const StyledIcon = styled.svg`
  background-color: ${props => props && props.color ? props.color : "black"};
  color: ${props => props && props.color ? props.color : "black"};
`;

const DownArrow = ({width, height, color}) => {
    return (
        <StyledIcon xmlns="http://www.w3.org/2000/svg"
             width={width} height={height}
                    viewBox={`0 0 ${width} ${height}`}>
        <path d="M5 8l4 4 4-4z" />
        </StyledIcon>
    )
};

const Icon = ({width, height, color}) => {

    return (
        <DownArrow color={color} height={height} width={width} />
    )

};

export default Icon;
