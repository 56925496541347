import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "tailwind.macro";
import CloudinaryUtils from "../CloudinaryUtils";
import useScreenType from "react-hooks-screen-type";

const StyledCloudinaryBackgroundImage = styled.div`
  ${(props) => {
    return css`
      background-image: url(${props.desktopImage});
      background-size: ${props.background_size};
    `;
  }};
  ${(props) => {
    if (props.is_parallax === "true") {
      return tw`bg-fixed`;
    }
  }}
  ${(props) => {
    if (props.background_height) {
      return css`
        height: ${props.background_height};
      `;
    }
  }}
  z-index:-1;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
`;

const CloudinaryBackgroundImage = ({
  url,
  url_mobile,
  background_size,
  background_height,
  background_size_mobile,
  background_height_mobile,
  children,
  image_width,
  image_height,
  image_width_mobile,
  image_height_mobile,
  quaility,
  is_parallax,
}) => {
  const screenType = useScreenType();
  const getCrop = () => {
    switch (background_size) {
      case "contain":
        return "fit";
      default:
        return "fill";
    }
  };

  const desktopImage = CloudinaryUtils.getOptimisedUrl(url, {
    width: image_width || 1920,
    height: image_height || 1080,
    crop: getCrop(),
    quality: quaility || 100,
  });

  const mobileImage = CloudinaryUtils.getOptimisedUrl(url, {
    width: image_width_mobile || 960,
    height: image_height || 720,
    crop: getCrop(),
    quality: quaility || 100,
  });

  const getPropertiesToApply = () => {
    let applyProperties = {};
    console.log("getPropertiesToApply: ");
    if (screenType === "xSmall" || screenType === "small") {
      applyProperties = {
        url: mobileImage || desktopImage,
        background_height: background_height_mobile || background_height,
        background_size: background_size_mobile || background_size,
      };
    } else {
      applyProperties = {
        url: desktopImage || mobileImage,
        background_height: background_height || background_height_mobile,
        background_size: background_size || background_size_mobile,
      };
    }
    return applyProperties;
  };
  let propertiesToApply = getPropertiesToApply();
  console.log("propertiesToApply: ", propertiesToApply);
  return (
    <StyledCloudinaryBackgroundImage
      is_parallax={is_parallax || false}
      background_size={propertiesToApply.background_size}
      desktopImage={propertiesToApply.url}
      background_height={propertiesToApply.background_height}
    >
      {children}
    </StyledCloudinaryBackgroundImage>
  );
};

export default CloudinaryBackgroundImage;
