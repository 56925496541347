import React from "react"
import Header from "../../../../omochi-components/src/components/Header/Header"
import { graphql, StaticQuery } from "gatsby"
import { LayoutProvider, LayoutConsumer } from "./context"
import Footer from "../../../../omochi-components/src/components/Footer/Footer"
import * as R from "ramda"
import {I18NConsumer, I18NProvider} from "../i18n/Context"
import { Helmet } from "react-helmet/es/Helmet"

const Layout = ({
  children,
  navBarCanBeTransparent,
  hideHeader,
  hideFooter,
  isDistractionFree,
}) => (
    <I18NProvider>
        <LayoutProvider>
            <LayoutConsumer>
                {({ navbarTransparent }) => (
                    <StaticQuery
                        query={graphql`
            query HeaderQuery {
              prismicHeader {
                data {
                  logo {
                    imageFile {
                      childImageSharp {
                        fixed {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                    alt
                    copyright
                    url
                  }
                  alternative_logo {
                    # imageFile {
                    #   childImageSharp {
                    #     fixed {
                    #       ...GatsbyImageSharpFixed
                    #     }
                    #   }
                    # }
                    # alt
                    # copyright
                    url
                  }
                  header_title {
                    html
                    text
                  }

                  links_en {
                    link_label {
                      html
                      text
                    }
                    link_url {
                      html
                      text
                    }
                    link_style
                  }

                  links {
                    link_label {
                      html
                      text
                    }
                    link_url {
                      html
                      text
                    }
                    link_style
                  }
                }
              }

              prismicFooter {
                data {
                  footer_logo {
                    alt
                    copyright
                    url
                  }
                  footer_color
                  footer_inverse_logo {
                    alt
                    copyright
                    url
                  }
                  body {
                    primary {
                      links_group_name {
                        html
                        text
                      }
                    }
                    items {
                      link_url {
                        html
                        text
                      }
                      link_label {
                        html
                        text
                      }
                    }
                  }
                }
              }
            }
          `}
                        render={({ prismicHeader, prismicFooter }) => {
                            const { data } = prismicHeader
                            const {
                                logo,
                                alternative_logo,
                                header_title,
                                links,
                                links_en,
                            } = data
                            return (
                                <I18NConsumer>
                                    {({ language }) => {
                                        const companyInfoSpanish = {
                                            addressMain: " Oficinas de venta | Atacama Inmocapital",
                                            addressSecondLine:
                                                "Ave. Samuel Lewis. Edf SL 55 (Torre óptima de seguros), piso 30, oficina 30-3",
                                            phone: "+507-6205-8718",
                                            phoneLink: "+50762058718",
                                            email: "info@forestapanama.com",
                                            disclaimer: "* Todos los valores de ingreso familiar son estimativos, basados en créditos otorgados previamente a nuestros clientes e históricos de la entidad bancaria. Aprobación sujeta a evaluación bancaria. Foresta Panamá Norte no tiene incidencia en el resultado final del proceso crediticio."
                                        }

                                        const companyInfoEnglish = {
                                            addressMain: "Sales Offices | Atacama Inmocapital",
                                            addressSecondLine:
                                                "Ave. Samuel Lewis. SL 55 Building (Torre óptima de seguros), floor 30, office 30-3",
                                            phone: "+507-6205-8718",
                                            phoneLink: "+50762058718",
                                            email: "info@forestapanama.com",
                                        }
                                        const companyInfoToUse =
                                            language === "english"
                                                ? companyInfoEnglish
                                                : companyInfoSpanish
                                        const linkToUse = language === "english" ? links_en : links

                                        return (
                                            <React.Fragment>
                                                {!hideHeader ? (
                                                    <Header
                                                        navbar_color={"primary-normal"}
                                                        isDistractionFree={isDistractionFree}
                                                        logo={logo}
                                                        alt_logo={alternative_logo}
                                                        navBarCanBeTransparent={navBarCanBeTransparent}
                                                        navbarTransparent={navbarTransparent}
                                                        links={linkToUse}
                                                        languageOptions={[
                                                            {
                                                                languageValue: "spanish",
                                                                languageLabel: "Español",
                                                                languageUrl: "/",
                                                                languageFlagUrl:
                                                                    "https://images.prismic.io/onix/42462dc5-d706-4fe8-bb7a-7485a151f210_mexico-flag-round-icon-256.png?auto=compress,format",
                                                            },
                                                            {
                                                                languageValue: "english",
                                                                languageLabel: "English",
                                                                languageUrl: "/en",
                                                                languageFlagUrl:
                                                                    "https://images.prismic.io/onix/5ef75332-1796-439f-9e4b-0505b77afb19_united-states-of-america-flag-round-large.png?auto=compress,format",
                                                            },
                                                        ]}
                                                    />
                                                ) : null}
                                                <Helmet>
                                                    <script src="https://www.googleoptimize.com/optimize.js?id=OPT-KTW56VP"></script>
                                                </Helmet>

                                                <div>{children}</div>
                                                {!hideFooter ? (
                                                    <Footer
                                                        companyInfo={companyInfoToUse}
                                                        displaySectionTitles={false}
                                                        footerColor={R.path(
                                                            ["data", "footer_color"],
                                                            prismicFooter
                                                        )}
                                                        footerInverseLogo={R.path(
                                                            ["data", "footer_inverse_logo"],
                                                            prismicFooter
                                                        )}
                                                        footerLogo={R.path(
                                                            ["data", "footer_logo"],
                                                            prismicFooter
                                                        )}
                                                        footerBody={R.path(["data", "body"], prismicFooter)}
                                                        footerSocialLinks={[
                                                            {
                                                                id: "facebook",
                                                                link: "https://www.facebook.com/forestapanama/",
                                                                icon:
                                                                    "https://images.prismic.io/forestapanama/d5185050-871b-47da-a8db-3eb4aded7292_FACEBOOK.png?auto=compress,format",
                                                            },
                                                            {
                                                                id: "instagram",
                                                                link: "https://www.instagram.com/foresta.panama/",
                                                                icon:
                                                                    "https://images.prismic.io/forestapanama/260573d8-0774-4ad5-b58c-0b1742220458_INSTAGRAM.svg?auto=compress,format",
                                                            },
                                                            {
                                                                id: "twitter",
                                                                link: "https://twitter.com/ForestaPanama",
                                                                icon:
                                                                    "https://images.prismic.io/forestapanama/e500fcc0-579f-46c0-8022-fb72af7ac8bb_TWITTER.svg?auto=compress,format",
                                                            },
                                                            {
                                                                id: "linkendin",
                                                                link: "https://www.linkedin.com/company/31406316",
                                                                icon:
                                                                    "https://images.prismic.io/forestapanama/bd0d80de-832f-41fc-8f1d-73f33415175f_LINKEDIN.svg?auto=compress,format",
                                                            },
                                                            {
                                                                id: "vimeo",
                                                                link: "https://vimeo.com/398270882",
                                                                icon:
                                                                    "https://images.prismic.io/forestapanama/143c19be-edbb-4559-b374-b2beadfed5f8_VIMEO.svg?auto=compress,format",
                                                            },
                                                            {
                                                                id: "youtube",
                                                                link: "https://youtu.be/2VM-YGHGmXk",
                                                                icon:
                                                                    "https://images.prismic.io/forestapanama/197e78a7-a882-42f3-8574-b791960449d4_YOUTUBE.svg?auto=compress,format",
                                                            },
                                                        ]}
                                                    />
                                                ) : null}
                                            </React.Fragment>
                                        )
                                    }}
                                </I18NConsumer>
                            )
                        }}
                    />
                )}
            </LayoutConsumer>
        </LayoutProvider>
    </I18NProvider>

)

export default Layout
