import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import {motion, useCycle} from "framer-motion";

const HeaderMenuModalContainer = styled(motion.div)`
  ${tw`z-50 w-full h-screen absolute top-0 left-0`};
      ${props => {
        switch (props.color) {
            case("primary-lighter"): return tw`bg-primary-lighter text-navbarfont-normal`;
            case("primary-normal"): return tw`bg-primary-normal text-navbarfont-inverse`;
            case("primary-darker"): return tw`bg-primary-darker text-navbarfont-inverse`;
            case("black"): return tw`bg-black text-navbarfont-inverse`;
            case("white"): return tw`bg-black text-navbarfont-inverse`;
            default: return tw`bg-white text-navbarfont-normal`
        }
    }}
`;

const StyledCloseButton = styled.button`
  ${tw`text-3xl py-sm px-sm`}
`;

const ModalMenuContentContainer = styled.div`
  ${tw`flex flex-wrap my-auto mx-auto text-center`}
`;

const MenuModalHeader = styled.div`
  ${tw`flex w-full justify-end items-end mx-auto`}
`;

const LinkElement = ({ link }) => {
  const colors = {
    success: css`
      ${tw`border border-white rounded-full text-black bg-white px-sm py-sm`}
    `,
    normal: css`
      ${tw`text-navbarfont-normal`}
    `,
  };
  const styleToApply = css`
    ${colors[link.link_style]}
  `;

  return (
    <Link
      css={css`
        ${tw`mx-sm text-white`}
        ${styleToApply}
      `}
      to={link.link_url.text}
    >
      {link.link_label.text}
    </Link>
  );
};

const LinkElementContainer = styled.div`
  ${tw`w-full my-base`}
`;


const HeaderMenuModal = ({
     logo, title,
     links, onCloseClick,
     color, hasToShowModal,
}) => {
  return (
    <HeaderMenuModalContainer
        color={color}
      >
      <MenuModalHeader>
        <StyledCloseButton onClick={() => onCloseClick()}>X</StyledCloseButton>
      </MenuModalHeader>
      <ModalMenuContentContainer>
        {links.map(link => {
          return (
            <LinkElementContainer>
              <LinkElement link={link} />
            </LinkElementContainer>
          );
        })}
      </ModalMenuContentContainer>
    </HeaderMenuModalContainer>
  );
};

export default HeaderMenuModal;
