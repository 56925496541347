import React, { useState } from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import { navigate } from "gatsby";
import { css } from "@emotion/core";

const StyledSelector = styled.div`
  ${tw`inline-block relative`}
  &:hover {
    ${tw`cursor-pointer`}
  }
`;

const StyledSelectorSelection = styled.a`
  ${tw`inline-flex items-center mx-sm`}
`;

const StyledSelectorOptions = styled.div`
  ${(props) => {
    if (props.displayOptions) {
      return tw`absolute block`;
    }
    return tw`hidden`;
  }}
  ${tw`bg-white p-sm rounded text-black shadow`}
`;

const StyledSelectorElement = styled.div`
  ${tw`py-xsm px-sm w-full whitespace-no-wrap`}
  &:hover {
    ${tw`bg-blue-200`}
  }
`;

const LanguageElementLabel = styled.div`
  ${tw`inline-block`}
`;

const HeaderLinkDropDown = ({ label, link_url, links }) => {
  const [displayOptions, setDisplayOptions] = useState(false);

  return (
    <StyledSelector
      onMouseEnter={() => setDisplayOptions(true)}
      onMouseLeave={() => setDisplayOptions(false)}
      onClick={() => setDisplayOptions(true)}
    >
      <StyledSelectorSelection href={link_url}>{label}</StyledSelectorSelection>
      <StyledSelectorOptions displayOptions={displayOptions}>
        {links.map((link) => {
          return (
            <StyledSelectorElement
              onClick={() => {
                navigate(link_url + link.url);
              }}
            >
              <LanguageElementLabel>{link.label}</LanguageElementLabel>
              {/*<Icon  width={"18"} height={"18"} color={"white"} />*/}
            </StyledSelectorElement>
          );
        })}
      </StyledSelectorOptions>
    </StyledSelector>
  );
};

export default HeaderLinkDropDown;
